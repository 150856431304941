import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import GhostHead from "components/ghost/GhostHead";
import { Layout, TopicGrid, TopicList } from "../components";

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */

/* eslint-disable react/prop-types */
export const Head = ({ location }) => {
    const metaProps = { location };
    return <GhostHead metaProps={metaProps} />;
};
/* eslint-enable react/prop-types */

const Index = ({ data }) => {
    const posts = data.allGhostPost.edges;
    const tags = data.allGhostTag.edges;
    const latest = posts.filter((post, index) => index < 7);

    // 暴力解，看有沒有辦法可以先 get tag 再用 tag 去 graphql 拿 post 再修
    const filteredPosts = tags.map((tag) => {
        const filtered = posts.filter(
            function (post) {
                const found = post.node.tags.find(
                    (postTag) => postTag.name === tag.node.name
                );
                if (this.count < 7 && found) {
                    this.count++;
                    return true;
                }
                return false;
            },
            { count: 0 }
        );

        return {
            tag: tag.node.name,
            link: `/tag/${tag.node.slug}/`,
            data: filtered,
        };
    });

    const topicList = filteredPosts.filter((post, index) => index > 2);

    return (
        <Layout isHome>
            <main className="site-main gh-main">
                <div className="container gh-inner">
                    <TopicGrid data={latest} />
                    {filteredPosts.map(
                        (post, index) =>
                            index < 3 && (
                                <TopicGrid
                                    key={post.link}
                                    data={post.data}
                                    tag={post.tag}
                                    link={post.link}
                                />
                            )
                    )}

                    <TopicList data={topicList} />
                </div>
            </main>
        </Layout>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.shape({
            edges: PropTypes.array.isRequired,
        }).isRequired,
        allGhostTag: PropTypes.shape({
            edges: PropTypes.array.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($skip: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            skip: $skip
            filter: { authors: { elemMatch: { slug: { ne: "gliacloud" } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
        allGhostTag(
            filter: {
                count: { posts: { gt: 1 } }
                visibility: { eq: "public" }
            }
            limit: 6
            sort: { order: DESC, fields: count___posts }
        ) {
            edges {
                node {
                    name
                    slug
                    postCount
                }
            }
        }
    }
`;
